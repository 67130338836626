import React from "react";
import { Link } from 'react-router-dom';
import authLayout from "../hoc/authLayout";
import logoimage from "../img/friendsup-logo.png";
import faviconimg from "../img/favicon.png";
import axios from "axios";

class login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
  }

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      console.log(this.state)
    });
  };

  handleLogin = async () => {
    try {
      const { email, password } = this.state;
      const response = await axios.post(
        'http://192.168.29.25:5001/api/user/login',
        {
          "phone": email,
          "apptype": "ts",
          "password": password,
          "fcm_token": "ADFSDFASDFASDFASDFDSAF",
          "device_id": "adsfasffkj-asdf-asdf-asdf-sdf"
        },
        {
          headers: {
            'x-access-token': '',
          },
        }
      );

      const userData = response.data.data;
      localStorage.setItem('token', userData.token);
      localStorage.setItem('user_id', userData.user_id);
      localStorage.setItem('user', JSON.stringify(userData));

      //this.props.history.push(`/userlist/${userData.user_id}`);

      // this.props.history.push('/', {
      //   user: userData.user_id,
      // });
    } catch (err) {
      console.error('Error:', err);
      this.setState({ error: 'Login failed. Please check your credentials.' });
    }
  };

  generateRazorpayLink = async () => {
    try {
      const { email, password } = this.state;
      const response = await axios.post(
        'http://192.168.29.25:5001/api/razorpayment/subscribe',
        // {
        //   "phone": email,
        //   "apptype": "ts",
        //   "password": password,
        //   "fcm_token": "ADFSDFASDFASDFASDFDSAF",
        //   "device_id": "adsfasffkj-asdf-asdf-asdf-sdf"
        // },
        {
          headers: {
            'x-access-token': '',
          },
        }
      );

      const userData = response.data.data;
      return response.data;
      // localStorage.setItem('token', userData.token);
      // localStorage.setItem('user_id', userData.user_id);
      // localStorage.setItem('user', JSON.stringify(userData));

      //this.props.history.push(`/userlist/${userData.user_id}`);

      // this.props.history.push('/', {
      //   user: userData.user_id,
      // });
    } catch (err) {
      console.error('Error:', err);
      this.setState({ error: 'Login failed. Please check your credentials.' });
    }
  };

  paymentVerification = async (payload) => {
    try {
      const { email, password } = this.state;
      const response = await axios.post(
        'http://192.168.29.25:5001/api/razorpayment/paymentVerification',
        payload,
        {
          headers: {
            'x-access-token': '',
          },
        }
      );

      const userData = response.data.data;
      // localStorage.setItem('token', userData.token);
      // localStorage.setItem('user_id', userData.user_id);
      // localStorage.setItem('user', JSON.stringify(userData));

      return response.data;
      //this.props.history.push(`/userlist/${userData.user_id}`);

      // this.props.history.push('/', {
      //   user: userData.user_id,
      // });
    } catch (err) {
      console.error('Error:', err);
      this.setState({ error: 'Login failed. Please check your credentials.' });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { email, password } = this.state;

    if (email === '') {
      this.setState({ error: 'Please enter a valid email' });
    } else if (password === '') {
      this.setState({ error: 'Please enter a valid password' });
    } else {
      this.setState({ error: '' });
      this.handleLogin();
    }
  };

  handleSignupEmailKeyPress = e => {
    if (e.keyCode === 13) {
      document.getElementById("txtpassword").focus();
    }
  };
  handleSignupPasswordKeyPress = e => {
    if (e.keyCode === 13) {
      document.getElementById("btnlogin").click();
    }
  };
  render() {
    const { email, password } = this.state;
    return <>


      <section className="intro mt-5 py-5">
        <div className="container">
          <div className="row mt-5" >
            <div className="col-md-6 text-center">
              <div>
                <div className="product-wrapper">
                  <span >Login</span>
                  <table className="table" >
                    <tr>
                      <td  >
                        <div className="form-group"  >
                          <input type="text" name="email" className="form-control py-2 h-14  x-5" id="txtemail" placeholder="Enter email" value={email}
                            onChange={this.handleInputChange} onKeyUp={this.handleSignupEmailKeyPress} />
                          {/* Style="color: #f2f2f2; opacity: 0.7; background: #0d0d0d !important; border: white; padding: 12px 20px; border-radius: 12px;"
                             */}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td  >

                        <div className="form-group" >
                          <input name="password" type="password" className="form-control  py-2 h-14  x-5" id="txtpassword" placeholder="Enter password" value={password}
                            onChange={this.handleInputChange} onKeyUp={this.handleSignupPasswordKeyPress} />
                          {/* Style="color: #f2f2f2; opacity: 0.7; background: #0d0d0d !important; border: white; padding: 12px 20px; border-radius: 12px;" */}
                        </div>

                      </td>
                    </tr>
                    <tr>
                      <td >

                        <div >
                          <a className="h-auto p-0 text-sm font-semibold text-gray-700 underline transition-colors hover:text-primary hover:no-underline" href="#">Forgot Password?</a>
                          {/* <button type="button"  className="form-control  py-2 h-14  x-5" id="btnlogin"  onClick={e => this.handleSubmit(e)}>Sign In</button>   */}
                          <button type="button" className="form-control  py-2 h-14  x-5" id="btnlogin" onClick={async () => {
                            let temp1 = {

                            }
                            const data = await this.generateRazorpayLink();
                            if (data && data.success == true) {
                              const options = {
                                key: "rzp_test_m23hMxflvrLPeG",
                                amount: data.data.totalAmount,
                                currency: "INR",
                                name: "Friendsup",
                                description: "Subtitle",
                                // image: `${process.env.REACT_PAYMENT_URL}/assets/images/Logo.png`,
                                //  image: `${process.env.REACT_PAYMENT_URL}/assets/images/Logo.png`,
                                order_id: data.data.razorpayId,
                                handler: (response) => this.paymentVerification(response),
                                prefill: {
                                  name: "Fenil Donda",
                                  email: "fenil.galaxy@gmail.com",
                                  contact: "9909904981",
                                },
                                notes: {
                                  address: "",
                                },
                                theme: {
                                  color: "#121212",
                                },
                              };
                              const razor = new window.Razorpay(options);
                              razor.open();
                            }


                          }}>Sign In</button>
                          {/* <asp:Label ID="lblloginmsg" Style="font-size: 14px; color: Red; font-family: Arial;"
                            Text=""  /> */}
                        </div>

                      </td>
                    </tr>
                  </table>


                </div>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div >
                <div className="product-wrapper">
                  <span  >Registration</span>
                  <table className="table" >
                    <tr>
                      <td>

                      </td>
                    </tr>
                    <tr>
                      <td>

                      </td>
                    </tr>
                    <tr>
                      <td>

                      </td>
                    </tr>
                    <tr>
                      <td>


                      </td>
                    </tr>
                    <tr>
                      <td>

                        <div id="div1" className="form-group" >

                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>

                        <div id="div5" className="form-group" >

                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>

                        <div>

                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>




      <div className="flex min-h-screen w-full flex-col justify-between">
        <header className="flex items-center justify-between p-4 lg:px-16 lg:py-6 2xl:px-24">
          <a href="#">
            <img alt="Siscall" fetchPriority="high" width="155" height="28" decoding="async" data-nimg="1" className="dark:invert logoic" src={logoimage} />
          </a>
          <div className="flex items-center space-x-2 md:space-x-4">
            <a className="inline-flex items-center gap-x-1 rounded-3xl p-2 py-1 text-sm font-medium transition-colors hover:bg-gray-200 md:px-4 md:py-2.5 [&amp;&gt;svg]:w-4 bg-gray-100 text-gray-900 [&amp;&gt;svg]:text-gray-900" href="#">
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 256" className="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M189.66,122.34a8,8,0,0,1,0,11.32l-72,72a8,8,0,0,1-11.32-11.32L164.69,136H32a8,8,0,0,1,0-16H164.69L106.34,61.66a8,8,0,0,1,11.32-11.32ZM216,32a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32Z"></path>
              </svg>
              <span>Login</span>
            </a>
          </div>
        </header>
        <div className="flex w-full flex-col justify-center px-5">
          <div className="mx-auto w-full max-w-md md:max-w-lg lg:max-w-xl 2xl:pb-8 ">
            <div className="flex flex-col items-center">
              <a className="mb-0 inline-block max-w-[64px] lg:mb-9" href="#"><img alt="SISCALL" className="transparent" loading="lazy" width="61" height="38" decoding="async" data-nimg="1" src={faviconimg} /></a>
              <h2 className="rizzui-title-h2 mb-7 text-center text-[28px] font-bold leading-snug md:text-3xl md:!leading-normal lg:mb-10 lg:text-4xl logtitle">Welcome Back! <br /> Sign in with your credentials.</h2>
            </div>
            <form noValidate="">
              <div className="space-y-5 lg:space-y-6">
                <div className="form-group">
                  <label for="productname" className="form-label">Email</label>
                  <div className="form-control-wrap">
                    <input type="text" name="email" className="form-control py-2 h-14  x-5" id="txtemail" placeholder="Enter email" value={email}
                      onChange={this.handleInputChange} onKeyUp={this.handleSignupEmailKeyPress} />
                  </div>
                </div>
                <div className="form-group">
                  <label for="productname" className="form-label">Password</label>
                  <div className="form-control-wrap">
                    <input name="password" type="password" className="form-control  py-2 h-14  x-5" id="txtpassword" placeholder="Enter password" value={password}
                      onChange={this.handleInputChange} onKeyUp={this.handleSignupPasswordKeyPress} />
                  </div>
                </div>

                <div className="flex items-center justify-content-end pb-1">
                  <a className="h-auto p-0 text-sm font-semibold text-gray-700 underline transition-colors hover:text-primary hover:no-underline" href="#">Forgot Password?</a>
                </div>

                <button type="button" className="rizzui-button inline-flex font-medium items-center justify-center active:enabled:translate-y-px focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-50 transition-colors duration-200 px-8 py-2.5 text-base h-14 rounded-md border border-transparent focus-visible:ring-offset-2 bg-gray-900 hover:enabled::bg-gray-800 active:enabled:bg-gray-1000 focus-visible:ring-gray-900/30 text-gray-0 w-full"
                  id="btnlogin" text="Sign In" onClick={e => this.handleSubmit(e)}></button>
              </div>
            </form>
            <p className="mt-6 text-center text-[15px] leading-loose text-gray-500 md:mt-7 lg:mt-9 lg:text-base">
              Having trouble accessing your account? <a className="font-semibold text-gray-700 transition-colors hover:text-primary" href="#">Contact us</a>
            </p>
          </div>
        </div>
        <footer className="flex flex-col-reverse items-center justify-between px-4 py-5 lg:flex-row lg:px-16 lg:py-6 2xl:px-24 2xl:py-10 .footerpadding">
          <div className="text-center leading-relaxed text-gray-500 lg:text-start">
            © Copyright 2023.  <a className="font-medium transition-colors hover:text-primary" href="#">SISCALL</a>, all rights reserved.
          </div>
          <div className="-mx-2.5 flex items-center justify-end pb-3 font-medium text-gray-700 lg:w-1/2 lg:pb-0"><a className="px-2.5 py-1.5 transition-colors hover:text-primary" href="#">Help</a><a className="px-2.5 py-1.5 transition-colors hover:text-primary" href="#">Privacy</a><a className="px-2.5 py-1.5 transition-colors hover:text-primary" href="#">Terms</a></div>
        </footer>
      </div>


    </>
  }
}

export default authLayout(login);