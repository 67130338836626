// import 'font-awesome/css/font-awesome.min.css';
// import './assets/css/style20d4.css?v1.1.2';
import './css/main.css';  
import './css/styles.css'; 
import './js/jquery-3.4.1.min.js'; 
import './js/custom-cursor.js';  
import './js/lax.js'; 
import './js/script.js'; 
// import './assets/js/scripts.js'; 
// import './assets/js/jquery.min.js';
// import './assets/js/bootstrap.min.js';  
// import './assets/js/select2.min.js';  
// import DashboardPage from './pages/DashboardPage';
// import HospitalList from './pages/hospitalList.js'
// import TypographyPage from './pages/TypographyPage'
import Index from './pages/login'
// import Dashboard from './pages/dashboard'
// import ResetPassword from './pages/auth/ResetPassword';
// import ProfilePage from './pages/profile/ProfilePage';
// import ChangePasswordPage from './pages/profile/ChangePasswordPage';
// import UserPreferencesPage from './pages/profile/UserPreferencesPage'
// import AdminBlankPage from './pages/AdminBlankPage';
// import UserPage from './pages/userList';
// import HomePage from './pages/home';
// import DesignationPage from './pages/designationList';
// import HospitalSubscriptionList from './pages/hospitalSubscriptionList.js';
// import SubscriptionPage from './pages/subscriptionList.js';
// import HomeScreen from './pages/default.js';
// import UserEntry from './pages/userEntry.js';
// import DesignationEntry from './pages/designationEntry.js';
// import SubscriptionEntry from './pages/subscriptionEntry.js';
// import HospitalEntry from './pages/hospitalEntry.js';
// import HospitalSubscriptionEntry from './pages/hospitalSubscriptionEntry.js';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
function App() {
    return (
        <Router>
            <Routes>
                {/* <Route exact path='/' element={<DashboardPage />} /> */}
                {/* <Route exact path='/login' element={<Login />} /> */}
                <Route exact path='/login' element={<Index />} />
                {/* <Route exact path='/hospitallist' element={<HospitalList />} />
                <Route exact path='/dashboard' element={<Dashboard />} />
                <Route exact path='/reset-password' element={<ResetPassword />} />
                <Route exact path='/profile' element={<ProfilePage />} />
                <Route exact path='/change-password' element={<ChangePasswordPage />} />
                <Route exact path='/preferences' element={<UserPreferencesPage />} />
                <Route exact path='/typography' element={<TypographyPage />} />
                <Route exact path='/blank-page' element={<AdminBlankPage />} />
                <Route exact path='/userlist' element={<UserPage />} />
                <Route exact path='/designationlist' element={<DesignationPage />} />
                <Route exact path='/subscriptionList' element={<SubscriptionPage />} />
                <Route exact path='/userentry' element={<UserEntry />} /> 
                <Route exact path='/Home' element={<HomeScreen />} />
                <Route exact path='/hospitalsubscriptionlist' element={<HospitalSubscriptionList />} />
                
                <Route exact path='/designationentry' element={<DesignationEntry/>} />
                <Route exact path='/subscriptionentry' element={<SubscriptionEntry/>} />
                <Route exact path='/hospitalentry' element={<HospitalEntry/>} />
                <Route exact path='/hospitalsubscriptionentry' element={<HospitalSubscriptionEntry/>} /> */}
            </Routes>
        </Router>
    )
}

export default App;
